<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <validation-observer ref="GroupAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addSchedule">
        <!-- Organization-->
        <b-form-group
          v-if="is_staff"
          label="Organization"
          label-for="Organization"
        >
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <v-select
              v-model="generated_for"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              @input="onOrgChange"
              autocomplete
              :reduce="(organization) => organization.tenant_uid"
              disabled
            />
            <!-- {{ generated_for }} -->
            <!-- 
              <b-form-select
              v-model="organization"
              :options="organizations"
              :state="errors.length > 0 ? false : null"
              @change="onOrgChange"
            ></b-form-select> -->
            <!-- {{organization}} -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Team Name -->
        <b-form-group label="Name" label-for="Name">
          <template #label>
            <span>Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Report Name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="report_name"
              v-model="report_name"
              :state="errors.length > 0 ? false : null"
              name="report_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Select Frequency" label-for="selected_schedule">
          <template #label>
            <span>Select Frequency</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="selected_schedule"
            rules="required"
          >
            <b-form-select
              v-model="selected_schedule"
              :options="schedules"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Select Scope" label-for="selected_report_type">
          <template #label>
            <span>Select Scope</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="selected_report_type"
            rules="required"
          >
            <b-form-select
              v-model="selected_report_type"
              :options="report_types"
              @change="onScopeChange"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Select Group" v-if="selected_report_type == 1">
          <b-form-select
            class=""
            style="width: 100%"
            v-model="group_filter"
            :options="group_filter_options"
          >
          </b-form-select>
          <!-- {{ group_filter }} -->
        </b-form-group>
        <b-form-group label="Select Asset" v-if="selected_report_type == 2">
          <b-form-select
            class=""
            style="width: 100%"
            v-model="asset_filter"
            :options="asset_filter_options"
          >
          </b-form-select>
          <!-- {{ asset_filter }} -->
        </b-form-group>

        <b-form-group label="Notify Users">
          <!-- <b-form-select class="" v-model="notifyUser" :options="users" multiple :select-size="4">
          </b-form-select> -->
          <v-select
            v-model="notifyUser"
            multiple
            :options="users"
            label="text"
            taggable
            :reduce="(notifyUser) => notifyUser.value"
          />
        </b-form-group>

        <b-form-group label="Select Template" label-for="selected_schedule">
          <template #label>
            <span>Select Template</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="selected_template"
            rules="required"
          >
            <b-form-select
              v-model="selected_template"
              :options="templates"
              :state="errors.length > 0 ? false : null"
              @change="onTempChange"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-for="(variable, key) in items"
          :key="key"
          :label="variable.variable_label"
        >
          <template #label>
            <span>{{ variable.variable_label }}</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            :name="variable.variable_label"
            rules="required"
          >
            <b-form-input v-model="variable.variable_value" type="text">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button type="submit" variant="primary" :disabled="invalid" size="sm">
          Add
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      super_admin: this.$store.state.app.user.id == 1,
      is_staff: this.$store.state.app.user.is_staff,
      report_name: "",
      templates: [],
      selected_template: null,
      selectedOrganization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // generated_for: "",
      // organizations: [],
      schedules: [
        { value: null, text: "--Select Frequency--" },
        { value: 2, text: "Weekly" },
        { value: 5, text: "Monthly" },
        { value: 6, text: "Quarterly" },
        { value: 9, text: "Fortnightly" },
      ],
      report_types: [
        { value: null, text: "--Select Scope--" },
        { value: 0, text: "Organization" },
        { value: 1, text: "Asset Group" },
        { value: 2, text: "Asset" },
      ],
      selected_schedule: null,
      selected_report_type: 0,
      //   users: [{ value: null, text: "--Select Owner--" }],
      items: [],
      template_options: [],

      group_filter: null,
      group_filter_options: [{ value: null, text: "--Select Group--" }],

      asset_filter: null,
      asset_filter_options: [{ value: null, text: "--Select Asset--" }],
      notifyUser: "",
      users: [],
    };
  },
  computed: {
    generated_for() {
      return this.organizations.find(
        (org) => org.tenant_uid === this.$store.state.app.org_id
      );
    },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.generated_for) {
        return this.generated_for.tenant_uid;
      }
      return null;
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.generated_for) {
        return this.$store.state.app.organizationId;
        // return this.generated_for.id;
      }
      return null; // Handle the case when organization is not selected
    },
  },
  created: function () {
    // this.load();
    this.loadTemplates();
    if (this.selectedOrganizationRefid) {
      this.loadOrgUsers();
    }
  },
  methods: {
    // load: function () {
    //   if (this.is_staff) {
    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {
    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].sso_ref_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //     });
    //   }
    // },
    onScopeChange: function () {
      // this.group_filter_options = [{ value: null, text: "--Select Group--" }];
      // this.group_filter = null;
      if (this.selected_report_type == 1) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/group/list-all-asset-groups?org_id=" +
            this.selectedOrganizationRefid,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].group_id,
              text: res.data[key].group_name,
            };
            // console.log(res.data[key].profile.organization)
            self.group_filter_options.push(a);
          });
        });
      }
      if (this.selected_report_type == 2) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.selectedOrganizationRefid,
        };
        var self = this;
        this.$http(options).then((res) => {
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text: res.data.results[key].asset_name,
            };
            // console.log(res.data[key].profile.organization)
            self.asset_filter_options.push(a);
          });
        });
      }
    },
    // onOrgChange: function () {
    //   this.users = [];
    //   const o_options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "user/users/get-all-users?q_org_id=" +
    //       this.selectedOrganizationRefid,
    //   };
    //   var self = this;
    //   this.$http(o_options).then((res) => {

    //     res.data.map(function (value, key) {
    //       let user = {
    //         value: res.data[key].id,
    //         text: res.data[key].username,
    //       };

    //       self.users.push(user);
    //     });
    //   });
    //   this.loadOrgUsers();
    // },
    loadTemplates: function () {
      // if (this.is_staff) {
      // Load oragnization drop down
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "report/report-template/",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.results.map(function (value, key) {
          let org = {
            value: res.data.results[key].template_id,
            text: res.data.results[key].template_name,
          };
          // console.log(res.data[key].profile.organization)
          self.templates.push(org);
        });
      });
      // }
    },
    loadOrgUsers: function () {
      this.users = [];
      // Load User drop down
      let url =
        process.env.VUE_APP_BASEURL +
        "user/users/get-all-org-users?query=true" +
        "&q_org_id=" +
        this.selectedOrganizationRefid;

      // if (this.organization != null) {
      //   if (this.organization == "system") {
      //     url = url + "&role_type=" + 0;
      //   } else {
      //     url = url + "&q_org_id=" + this.selectedOrganizationRefid;
      //   }
      // }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;

      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let notifyUser = {
            value: res.data[key].id,
            text: res.data[key].first_name,
          };
          self.users.push(notifyUser);
        });
      });
    },
    onTempChange: function () {
      this.items = [];
      // Load template properties
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report-template/" +
          this.selectedOrganizationRefid,
      };
      var self = this;
      this.$http(options).then((res) => {
        let meta = JSON.parse(res.data.template_meta_fields);
        // self.items = meta
        meta.forEach(function (item, index) {
          let i = {
            id: item.id,
            variable: item.variable,
            variable_label: item.variable_label,
            variable_value: "",
          };
          self.items.push(i);
        });
      });
    },
    addSchedule: function () {
      this.$refs.GroupAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          // if (!this.is_staff) this.generated_for = this.$store.state.app.org_id;
          // this.$store.state.app.user.profile.organization;
          // var name = this.report_name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
          let meta = {};
          if (this.selected_report_type == 0)
            meta =
              '{"org_id":' + this.selectedOrganizationId + ',"format":"doc"}';
          if (this.selected_report_type == 1)
            meta = '{"group_id":' + this.group_filter + ',"format":"doc"}';
          if (this.selected_report_type == 2)
            meta = '{"asset_id":' + this.asset_filter + ',"format":"doc"}';
          let data = {
            report_name: this.report_name,
            generated_for: this.selectedOrganizationId,
            template: this.selected_template,
            report_schedule_type: this.selected_schedule,
            report_type: this.selected_report_type,
            meta: meta,
            template_meta_data: JSON.stringify(this.items),
            users_to_notify: this.notifyUser,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "report/schedule/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.schedule_id) {
              this.$router.push({ name: "List Scheduled Reports" });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Successfully added the schedule! You may now add assets to your group!",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>