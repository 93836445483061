var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"GroupAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addSchedule($event)}}},[(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"--Select Organization--","options":_vm.organizations,"state":errors.length > 0 ? false : null,"autocomplete":"","reduce":function (organization) { return organization.tenant_uid; },"disabled":""},on:{"input":_vm.onOrgChange},model:{value:(_vm.generated_for),callback:function ($$v) {_vm.generated_for=$$v},expression:"generated_for"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Report Name","rules":{
            required: true,
            regex: /^[a-zA-Z0-9\s\(\)\ [\]\ {\} \|\/ ]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"report_name","state":errors.length > 0 ? false : null,"name":"report_name"},model:{value:(_vm.report_name),callback:function ($$v) {_vm.report_name=$$v},expression:"report_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Select Frequency","label-for":"selected_schedule"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Select Frequency")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"selected_schedule","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.schedules,"state":errors.length > 0 ? false : null},model:{value:(_vm.selected_schedule),callback:function ($$v) {_vm.selected_schedule=$$v},expression:"selected_schedule"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Select Scope","label-for":"selected_report_type"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Select Scope")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"selected_report_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.report_types,"state":errors.length > 0 ? false : null},on:{"change":_vm.onScopeChange},model:{value:(_vm.selected_report_type),callback:function ($$v) {_vm.selected_report_type=$$v},expression:"selected_report_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.selected_report_type == 1)?_c('b-form-group',{attrs:{"label":"Select Group"}},[_c('b-form-select',{staticStyle:{"width":"100%"},attrs:{"options":_vm.group_filter_options},model:{value:(_vm.group_filter),callback:function ($$v) {_vm.group_filter=$$v},expression:"group_filter"}})],1):_vm._e(),(_vm.selected_report_type == 2)?_c('b-form-group',{attrs:{"label":"Select Asset"}},[_c('b-form-select',{staticStyle:{"width":"100%"},attrs:{"options":_vm.asset_filter_options},model:{value:(_vm.asset_filter),callback:function ($$v) {_vm.asset_filter=$$v},expression:"asset_filter"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Notify Users"}},[_c('v-select',{attrs:{"multiple":"","options":_vm.users,"label":"text","taggable":"","reduce":function (notifyUser) { return notifyUser.value; }},model:{value:(_vm.notifyUser),callback:function ($$v) {_vm.notifyUser=$$v},expression:"notifyUser"}})],1),_c('b-form-group',{attrs:{"label":"Select Template","label-for":"selected_schedule"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Select Template")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"selected_template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.templates,"state":errors.length > 0 ? false : null},on:{"change":_vm.onTempChange},model:{value:(_vm.selected_template),callback:function ($$v) {_vm.selected_template=$$v},expression:"selected_template"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._l((_vm.items),function(variable,key){return _c('b-form-group',{key:key,attrs:{"label":variable.variable_label},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(variable.variable_label))]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":variable.variable_label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(variable.variable_value),callback:function ($$v) {_vm.$set(variable, "variable_value", $$v)},expression:"variable.variable_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[_vm._v(" Add ")])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }